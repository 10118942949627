import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	Button,
	ButtonGroup,
	Chip,
	Divider,
	Fab,
	Input,
	InputAdornment,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	TextField,
	Tooltip,
} from '@material-ui/core';
import {
	Add as AddIcon,
	Delete as DeleteIcon,
	Edit as EditIcon,
	// FileCopy as DuplicateIcon,
	LockOpen as LockIcon,
	Remove as RemoveIcon,
	Share as ShareIcon,
} from '@material-ui/icons';
import { useConfirm } from 'material-ui-confirm';

import useStyles from './CollectionPage.styled';
import Modal from '../../components/shared/Modal';
import { useCollections } from '../../hooks/Collections';

const CollectionPage = () => {
	const { collections, deleteCollection, updateCollection } = useCollections();
	const confirm = useConfirm();
	const { id } = useParams();
	const classes = useStyles();
	const [edit, setEdit] = useState(false);
	const [localCollection, setLocalCollection] = useState();
	const [localAddField, setLocalAddField] = useState('');

	const headerItems = [
		{
			disabled: true, // TODO: Implement Share Collection
			icon: <ShareIcon />,
			// onClick: shareCollection,
			text: 'Share Collection',
		},
		// {
		// 	disabled: true, // TODO: Implement Duplicate Collection
		// 	icon: <DuplicateIcon />,
		// 	// onClick: duplicateCollection,
		// 	text: 'Duplicate Collection',
		// },
		{
			icon: <DeleteIcon />,
			onClick: () => {
				confirm({
					title: `Are you sure you want to delete "${localCollection.name}"?`,
					confirmationText: 'Delete',
				})
					.then(() => deleteCollection(id));
			},
			text: 'Delete Collection',
		},
	];

	const handleChangeAdd = (event) => setLocalAddField(event.target.value);

	const handleCollectionAdd = (event) => {
		event.preventDefault();

		if (localAddField.length !== 0) {
			const item = {
				count: 0,
				id: Date.now(),
				name: localAddField,
			};

			updateCollection(id, {
				...localCollection,
				items: [
					...localCollection.items,
					item,
				],
			});
			setLocalAddField('');
		}
	};

	const handleDecrementCount = (index) => {
		const tempItems = [...localCollection.items];

		const updateItems = (items) => updateCollection(id, {
			...localCollection,
			items,
		});

		if (tempItems[index].count <= 0) {
			confirm({
				title: `Are you sure you want to delete "${tempItems[index].name || 'Untitled'}"?`,
				confirmationText: 'Delete',
			})
				.then(() => {
					tempItems.splice(index, 1);
					updateItems(tempItems);
				});
		} else {
			tempItems[index].count -= 1;
			updateItems(tempItems);
		}
	};

	const handleIncrementCount = (index) => {
		const tempItem = { ...localCollection.items[index] };
		if (tempItem.count < 999) {
			tempItem.count += 1;
		}

		updateCollection(id, {
			...localCollection,
			items: [
				...localCollection.items.slice(0, index),
				tempItem,
				...localCollection.items.slice(index + 1),
			],
		});
	};

	const updateCollectionName = (event) => {
		const newCollection = {
			...localCollection,
			name: event.target.value,
		};

		updateCollection(id, newCollection);
	};

	const updateItemName = (event, index) => {
		const tempItem = { ...localCollection.items[index] };
		tempItem.name = event.target.value;

		updateCollection(id, {
			...localCollection,
			items: [
				...localCollection.items.slice(0, index),
				tempItem,
				...localCollection.items.slice(index + 1),
			],
		});
	};

	// Update the collection when collections changes
	useEffect(() => {
		const collection = collections.find((item) => String(item.id) === String(id));

		// If new collection set to edit mode by default
		if (collection?.name === '' || collection?.items.length === 0) {
			setEdit(true);
		}
		setLocalCollection(collection);
	}, [collections, id]);

	return (
		<Modal
			maxHeight
			headerItems={headerItems}
			// showPrompt={edit}
			title={!edit ? localCollection?.name || 'Untitled' : (
				<Input
					autoComplete="off"
					fullWidth
					className={classes.collectionName}
					id="local-collection-name"
					placeholder="Name of the Collection"
					onChange={updateCollectionName}
					value={localCollection?.name}
				/>
			)}
		>
			{edit && (
				<form
					autoComplete="off"
					className={classes.form}
					noValidate
					onSubmit={handleCollectionAdd}
				>
					<TextField
						fullWidth
						id="local-collection-add"
						label="Item to add to Collection"
						onChange={handleChangeAdd}
						value={localAddField}
						variant="outlined"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Button
										className={classes.button}
										color="primary"
										disabled={localAddField.length <= 0}
										onClick={handleCollectionAdd}
										startIcon={<AddIcon />}
										variant="contained"
									>
										Add
									</Button>
								</InputAdornment>
							),
						}}
					/>
				</form>
			)}
			<List>
				{localCollection?.items.length === 0 && (
					<ListItem>
						<ListItemText primary="No items in the collection yet" />
					</ListItem>
				)}
				{localCollection?.items.map((item, index) => (
					<React.Fragment key={item.id}>
						<ListItem>
							<ListItemText
								className={classes.listItemText}
								primary={!edit ? item.name : (
									<Input
										autoComplete="off"
										className={classes.input}
										placeholder="Item name"
										onChange={(event) => updateItemName(event, index)}
										value={item.name}
									/>
								)}
							/>
							{!edit ? (
								<ListItemSecondaryAction>
									<Chip color="primary" label={item.count} />
								</ListItemSecondaryAction>
							) : (
								<ListItemSecondaryAction>
									<ButtonGroup color="primary" aria-label="outlined primary button group">
										<Button
											onClick={() => handleDecrementCount(index)}
											variant="contained"
										>
											<RemoveIcon />
										</Button>
										<Button
											component="div"
											className={classes.itemCount}
											disableRipple
										>
											{item.count}
										</Button>
										<Button
											onClick={() => handleIncrementCount(index)}
											variant="contained"
										>
											<AddIcon />
										</Button>
									</ButtonGroup>
								</ListItemSecondaryAction>
							)}
						</ListItem>

						{localCollection?.items.length - 1 !== index && <Divider />}
					</React.Fragment>
				))}
			</List>

			<Tooltip title={edit ? 'Lock' : 'Edit'}>
				<Fab
					color="primary"
					aria-label={edit ? 'Lock' : 'Edit'}
					className={classes.fab}
					onClick={() => setEdit(!edit)}
				>
					{edit ? <LockIcon /> : <EditIcon />}
				</Fab>
			</Tooltip>
		</Modal>
	);
};

export default CollectionPage;
