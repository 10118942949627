import PropTypes from 'prop-types';

import CollectionsContext from './CollectionsContext';
import useCollectionsValue from './useCollectionsValue';

const propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

const CollectionsProvider = ({ children }) => {
	const value = useCollectionsValue();
	return <CollectionsContext.Provider value={value}>{children}</CollectionsContext.Provider>;
};

CollectionsProvider.propTypes = propTypes;

export default CollectionsProvider;
