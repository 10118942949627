import React from 'react';
import {
	Fab,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip,
} from '@material-ui/core';
import {
	Add as AddIcon,
	KeyboardArrowRight as ArrowIcon,
} from '@material-ui/icons';

import useStyles from './HomePage.styled';
import RouterLink from '../../components/shared/RouterLink';
import { useCollections } from '../../hooks/Collections';

const HomePage = () => {
	const classes = useStyles();
	const { collections, createCollection } = useCollections();

	return (
		<>
			<List>
				{collections.length === 0 && (
					<ListItem>
						<ListItemText primary="No collections found" />
					</ListItem>
				)}
				{collections.map((item) => (
					<ListItem
						key={item.id}
						button
						className={classes.listItem}
						component={RouterLink}
						to={{
							pathname: `/collection/${item.id}`,
							state: {
								modal: true,
							},
						}}
					>
						<ListItemText className={classes.listItemText} primary={item.name || 'Untitled'} />
						<ListItemSecondaryAction className={classes.listItemSecondary}>
							<ArrowIcon color="disabled" />
						</ListItemSecondaryAction>
					</ListItem>
				))}
			</List>

			<Tooltip title="Add">
				<Fab
					aria-label="Add"
					className={classes.fab}
					color="primary"
					onClick={createCollection}
				>
					<AddIcon />
				</Fab>
			</Tooltip>
		</>
	);
};

export default HomePage;
