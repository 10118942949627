import React from 'react';
import {
	Divider,
	List,
} from '@material-ui/core';

import AppVersion from '../../components/shared/AppVersion';
import ChangeTheme from '../../components/shared/ChangeTheme';
import CheckForInstallPrompt from '../../components/shared/CheckForInstallPrompt';
import CheckForUpdate from '../../components/shared/CheckForUpdate';
import Modal from '../../components/shared/Modal';

const SettingsPage = () => (
	<Modal title="Settings">
		<List>
			<CheckForInstallPrompt />

			<AppVersion />
			<CheckForUpdate />

			<Divider />

			<ChangeTheme />
		</List>
	</Modal>
);

export default SettingsPage;
