import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	AppBar,
	Toolbar,
	Typography,
} from '@material-ui/core';
import {
	Settings as SettingsIcon,
} from '@material-ui/icons';

import useStyles from './Container.styled';
import RouterLink from '../shared/RouterLink';
import HeaderContent from '../shared/HeaderContent';
import { useHotkeys } from '../../hooks/Hotkeys';

const propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

const Container = ({ children }) => {
	const classes = useStyles();

	const headerItems = [
		{
			component: RouterLink,
			icon: <SettingsIcon />,
			text: 'Settings',
			to: {
				pathname: '/settings/',
				state: { modal: true },
			},
		},
	]; // eslint-disable-line

	useHotkeys([
		// P or S = Disable Event
		{
			keys: ['p', 's'],
			callback: (event) => event.preventDefault(),
			metaModifier: true,
		},
	]);

	return (
		<div className={classes.container}>
			<AppBar position="fixed">
				<Toolbar>
					<Typography className={classes.title} variant="h6" noWrap>
						{process.env.REACT_APP_TITLE}
					</Typography>
					<HeaderContent headerItems={headerItems} forceLastIconEdge />
				</Toolbar>
			</AppBar>
			<div className={clsx(classes.content, 'MuiPage')}>
				<div className={classes.drawerHeader} />
				<main>
					{children}
				</main>
			</div>
		</div>
	);
};

Container.propTypes = propTypes;

export default Container;
