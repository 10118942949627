import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	collectionName: {
		color: 'white',
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	form: {
		marginTop: theme.spacing(2),
		padding: theme.spacing(1),
	},
	itemCount: {
		width: 48,
		color: theme.palette.type === 'dark' ? theme.palette.text.primary : null,
	},
	listItem: {
		'&:hover,&.active': {
			backgroundColor: 'rgba(0, 0, 0, 0.15)',
		},
	},
	listItemText: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	input: {
		maxWidth: '50%',
	},
}));

export default useStyles;
